import React from 'react'
import Categories from './Categories'
import List from './List/List'
import categoriesList from '../../../static/relatorios/categorias.json'

import * as S from './styles'

interface IManagementReports {
  category: string;
}
interface ICategoryItem {
  id: string;
  name: string;
  icon: string;
  description: string;
}

const ManagementReports: React.FC<IManagementReports> = ({ category }: IManagementReports) => {
  const selectedCategory = categoriesList.find((c: ICategoryItem) => c.id === category)
  return (
    <div className='row'>
      <S.CategoryContainer className='col-12 col-md-4'>
        <Categories
          selected={selectedCategory as ICategoryItem}
          list={categoriesList}
          className='mb-md-1'
        />
      </S.CategoryContainer>
      <div className='col-12 col-md-8 mb-4 mb-md-0 pl-md-0 bg-white'>
        <List
          fundo={category}
          selectedCategory={selectedCategory as ICategoryItem}
        />
      </div>
    </div>
  )
}

export default ManagementReports
