import styled, { FlattenSimpleInterpolation } from 'styled-components'
import breakpoints from '../../styles/breakpoints'

interface IBanner {
  customStyle?: FlattenSimpleInterpolation;
}

export const Banner = styled.section<IBanner>`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 140px;
  display: flex;
  align-items: flex-end;
  position: relative;

  @media (min-width: ${breakpoints.md}) {
    height: 178px;
  }

  @media (min-width: ${breakpoints.lg}) {
    align-items: center;
    padding-bottom: 0;
  }

  @media (min-width: ${breakpoints.xl}) {
    height: 252px;
  }

  ${(props: IBanner) => props.customStyle && props.customStyle}
 `
