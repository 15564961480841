import styled, { css } from 'styled-components'
import { orange, gray } from '../../../styles/colors'

interface ICategoryOption {
  $selected: boolean;
}

export const MenuCategories = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding:0 0 24px 0;
  
  .item {
    color: ${gray['700']};
    text-decoration: none;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      color: ${orange.base};
    }

    span {

      &:hover {
        color: ${orange.base}
      }
    }
  }
`

const selectedOption = css`
  background-color: ${gray[400]};
  span {
    color: ${orange.medium};
    font-weight: 700;
  }

  svg {
    color: '#B6B7BB' !important;
  }
`

export const CategoryOption = styled.a<ICategoryOption>`
  border-bottom: 1px solid ${gray[550]};
  span {
    color: ${gray[750]};
  }

  svg {
    color: ${gray[750]} !important;
  }

  transition: .3s ease-out;

  ${(props: ICategoryOption) => props.$selected && selectedOption}
`
