import * as React from 'react'
import {
  Select as BaseSelect,
  selectClasses,
  SelectProps,
  SelectRootSlotProps,
} from '@mui/base/Select'
import { Option as BaseOption, optionClasses } from '@mui/base/Option'
import { Popper as BasePopper } from '@mui/base/Popper'
import IntercoIcons from '../IntercoIcons'
import { gray, white } from '../../styles/colors'
import { orange } from '@mui/material/colors'
import { styled } from '@mui/system'

const Button = React.forwardRef(function Button<
  TValue extends {},
  Multiple extends boolean,
> (
  props: SelectRootSlotProps<TValue, Multiple>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const { ownerState, ...other } = props
  return (
    <button type='button' {...other} ref={ref}>
      {other.children}
      <IntercoIcons icon='chevron-down' size='MD' color='#161616' />
    </button>
  )
})

const StyledButton = styled(Button, { shouldForwardProp: () => true })`
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 20px;
  box-sizing: border-box;
  width: 100%;
  padding: 16px 8px;
  text-align: left;
  background: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid ${gray[550]};
  color: ${gray[750]};
  position: relative;

  display: flex;
  justify-content: space-between;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:focus {
    outline: none !important;
  }

  &.${selectClasses.focusVisible} {
    outline: 0;
    border-color: ${orange[400]};
    box-shadow: 0 0 0 3px ${orange[200]};
  }

  & > svg {
    font-size: 1rem;
    position: absolute;
    height: 100%;
    top: 0;
    right: 10px;
  }
`

const Listbox = styled('ul')`
  font-family: Inter, sans-serif;
  font-size: 16px;
  line-height: 20px;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 320px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: ${white};
  color: ${gray[750]};
  box-shadow: 0px 2px 4px rgba(0,0,0, 0.05);
  `

export const Option = styled(BaseOption)`
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionClasses.selected} {
    background-color: ${orange[100]};
    color: ${gray[750]};
  }

  &.${optionClasses.highlighted} {
    background-color: ${orange[300]};
    color: ${gray[750]};
  }

  &.${optionClasses.highlighted}.${optionClasses.selected} {
    background-color: ${orange[300]};
    color: ${gray[750]};
  }

  &.${optionClasses.disabled} {
    color: ${gray[750]};
  }

  &:hover:not(.${optionClasses.disabled}) {
    background-color: ${orange[100]};
    color: ${gray[750]};
  }
  `

const Popper = styled(BasePopper)`
  z-index: 1;
`

export const Select = React.forwardRef(function CustomSelect<
  TValue extends {},
  Multiple extends boolean,
> (props: SelectProps<TValue, Multiple>, ref: React.ForwardedRef<HTMLButtonElement>) {
  const slots = {
    root: StyledButton,
    listbox: Listbox,
    popper: Popper,
    ...props.slots,
  }

  return <BaseSelect {...props} ref={ref} slots={slots} />
})
