import React, { useEffect, useState, SetStateAction, ChangeEvent } from 'react'
import LoadingIndicator from '../../LoadingIndicator'
import SvgIcon from '../../Icon'
import DocumentCard from '../../DocumentCard'

interface ICategoryItem {
  id: string;
  name: string;
  icon: string;
  description: string;
}

interface IDocumentCard {
  title: string;
  description: string;
  date: string;
  url: string;
}

interface IImport {
  default: SetStateAction<never[]>;
}

interface IList {
  selectedCategory: ICategoryItem;
  fundo: string;
}

const loading = (
  <div className='d-flex align-items-center justify-content-center py-3'>
    <LoadingIndicator />
  </div>
)

const noMatchingRecords = (
  <div className='text-center text-gray-600 fs-14 lh-20 py-3'>
    Nenhum resultado correspondente ao termo pesquisado.
  </div>
)

const emptyCategory = (
  <div className='text-center text-gray-600 fs-14 lh-20 py-3'>
    Esta categoria ainda não possui nenhum documento.
  </div>
)

const List: React.FC<IList> = ({ selectedCategory, fundo }: IList) => {
  const [ fetching, setFetching ] = useState(true)
  const [ itemsList, setItemsList ] = useState([])
  const [ filterTerm, setFilterTerm ] = useState('')

  useEffect(() => {
    if (!selectedCategory) return
    const categoryId = selectedCategory.id
    setFetching(true)
    import(`../../../../static/relatorios/pdfs/${categoryId}.json`)
      .then((data: IImport) => {
        setItemsList(data.default)
        setFetching(false)
      }).catch(() => {
        setItemsList([])
        setFetching(false)
      })
  }, [ selectedCategory && selectedCategory.id, fundo ])

  const filterRegex = new RegExp(filterTerm.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&'), 'gi')
  const list = itemsList
    .filter((item: IDocumentCard) => filterRegex.test(item.title) || filterRegex.test(item.description))
    .map((item: IDocumentCard) => <DocumentCard info={item} className='col-12 col-md-6' key={item.title} data-documento={item.title} />)

  if (list.length === 0) {
    list.push(filterTerm.length ? noMatchingRecords : emptyCategory)
  }

  if (!selectedCategory) return null

  return (
    <div id='central-de-resultados'>
      <div className='d-flex flex-column flex-lg-row align-items-lg-center pt-4 pt-md-0'>
        <h3 className='text-gray-750 fs-20 lh-24 fw-600 text-center f-sora flex-grow-1 text-md-left m-0 pb-2'>
          {selectedCategory.name}
        </h3>
        <div className='d-none d-md-flex rounded-4 align-items-center position-relative mt-md-2 mt-lg-0 ml-3 ml-md-0 ml-lg-3 px-2 bg-gray-400'>
          <span className='mr-2 d-flex align-items-center'>
            <SvgIcon width={22} height={22} directory='v2' icon='navigation/search' color='gray-base' />
          </span>
          <input
            value={filterTerm} onChange={(e: ChangeEvent<HTMLInputElement>) => setFilterTerm(e.target.value)} placeholder='Pesquisar' type='text'
            className='py-2 fs-14 lh-17 border-0 bg-gray-400 rounded-4 pl-2 h-100 text-gray-600'
          />
        </div>
      </div>
      <p className='fs-16 lh-20 my-3'>
        {selectedCategory.description}
      </p>
      <div className='row'>
        {fetching ? loading : list}
      </div>
    </div>
  )
}

export default List
