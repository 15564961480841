import React from 'react'
import { Link } from 'gatsby'
import SvgIcon from '../../Icon'
import * as S from './style'
import useDataLayer from '../../../hooks/useDataLayer/dataLayerBody'
import CategoryMobileSelect from '../../CategoryMobileSelect'
import IntercoIcons from '../../IntercoIcons'
interface ICategory {
  id: string;
  name: string;
  icon: string;
  description: string;
}

interface ICategories {
  selected: ICategory;
  list: ICategory[];
  className: string;
}

const Categories: React.FC<ICategories> = ({ selected, list, className }: ICategories) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <S.MenuCategories>
      <div className={`${className} px-4 px-md-0 w-100 pt-4 pt-md-0`}>
        <h2 className='pb-2 fs-20 fw-600 text-gray-750 f-sora d-none d-md-block'>Categorias</h2>
        <div className='d-block d-md-none'>
          <CategoryMobileSelect selectList={list} />
        </div>
        <div className='d-none d-md-block'>
          {list.map((category: ICategory) => (
            <S.CategoryOption
              as={Link}
              $selected={selected === category}
              key={category.name}
              to={`/acesso-a-informacao?category=${category.id}`}
              data-id={category.id}
              className='item flex-grow-1 d-flex align-items-center justify-content-between py-2 px-3 px-md-2 px-lg-4 w-100'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_02',
                  section_name: 'Acesso à informação',
                  element_name: category.name,
                  element_action: 'click button',
                  redirect_url: `https://interasset.com.br/acesso-a-informacao?category=${category.id}`,
                })
              }}
            >
              <span className='fs-16 lh-22'>{category.name}</span>
              <div>
                <IntercoIcons icon='chevron-right' size='MD' color={`${selected === category ? '#B6B7BB' : '#161616'}`} />
              </div>
            </S.CategoryOption>
          ))}
        </div>
      </div>
    </S.MenuCategories>
  )
}

export default Categories
