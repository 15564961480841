/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { PageProps } from 'gatsby'
import Banner from '../../components/Banner'
import Layout from '../../components/Layout/index'
import SEO from '../../components/Seo'
import ManagementReports from '../../components/ManagementReports'

const AcessoAInformacao: React.FC<PageProps> = ({ location }: PageProps) => {
  const [ category, setCategory ] = useState('compliance')

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    setCategory(searchParams.get('category') || 'compliance')
  }, [ location.search ])

  return (
    <>
      <Layout>
        <SEO title='Documentos - Inter Asset' description='Confira os documentos da Inter Asset' />
        <Banner imageMobile='backgroundBannerAcessoAInformacaoMobile' imageDesktop='backgroundBannerAcessoAInformacaoDesktop'>
          <div className='container'>
            <div className='row text-white align-items-center'>
              <div className='col-12 col-md-8 col-lg-5 text-left'>
                <h1 className='fs-23 lh-40 fs-md-40 lh-md-50 fs-xl-48 lh-xl-60 text-white fw-600 f-sora mb-0'>Documentos</h1>
              </div>
            </div>
          </div>
        </Banner>
        <div className='container py-md-5'>
          <div className='row'>
            <div className='col-12'>
              <ManagementReports category={category} />
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default AcessoAInformacao
