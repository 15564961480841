/* eslint-disable max-len */
import React from 'react'
import IntercoIcons from '../IntercoIcons'
import { DocumentCardList } from './style'
import { gray } from '../../styles/colors'

interface IDocumentCard {
  info: {
    title: string;
    description?: string;
    date: string;
    url: string;
  };
  className: string;
}

const DocumentCard: React.FC<IDocumentCard> = ({ info, className, ...rest }: IDocumentCard) => {
  return (
    <DocumentCardList className={className} {...rest }>
      <div className='list-item rounded-12 p-3 mb-3'>
        <div>
          <div className='d-flex align-items-center justify-content-between'>
            <div>
              <IntercoIcons icon='pdf-file' size='LG' color={gray[650]} className='pdf-icon' />
            </div>
            <div>
              <a href={info.url} target='_blank' rel='noreferrer' title='Download do arquivo' className='fs-12 lh-15 fs-md-14 lh-md-17 fw-700 text-orange-medium'>
                Abrir
              </a>
            </div>
          </div>
          <div className='flex-grow-1 d-flex flex-column pr-3'>
            <h3 className='fs-16 lh-20 fs-md-20 lh-md-26 fw-400 text-gray-750 f-sora mt-2 mb-4 mt-md-2 mb-md-1'>{info.title}</h3>
            <span className='fs-14 lh-17 fs-md-16 lh-md-20 fw-400 text-gray-650'>{info.date}</span>
          </div>
        </div>
      </div>
    </DocumentCardList>
  )
}

export default DocumentCard
