import React, { useState, useLayoutEffect, ReactNode } from 'react'
import { FlattenSimpleInterpolation } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

// Hooks
import useWidth from '../../hooks/useWidth'

// Styles
import { Banner } from './style'

const WIDTH_MD = 768

interface IBannerHeader {
  imageMobile: string;
  imageDesktop: string;
  customStyle?: FlattenSimpleInterpolation;
  children: ReactNode;
}

const BannerHeader: React.FC<IBannerHeader> = ({ imageMobile, imageDesktop, customStyle, children }: IBannerHeader): React.ReactElement => {
  const [ imageBanner, setImageBanner ] = useState('')
  const windowWidth: number = useWidth(200)

  const data = useStaticQuery(graphql`
    query {
      BannerRelatoriosMobile: imageSharp(fluid: {originalName: { regex: "/banner-relatorios-mobile/" }}) {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      BannerRelatoriosDesktop: imageSharp(fluid: {originalName: { regex: "/banner-relatorios-desktop/" }}) {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      BannerFundosMobile: imageSharp(fluid: {originalName: { regex: "/banner-fundos-mobile/" }}) {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      BannerFundosDesktop: imageSharp(fluid: {originalName: { regex: "/banner-fundos-desktop/" }}) {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      backgroundBannerAcessoAInformacaoMobile: imageSharp(fluid: {originalName: { regex: "/background-banner-acesso-a-informacao-mobile/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      backgroundBannerAcessoAInformacaoDesktop: imageSharp(fluid: {originalName: { regex: "/background-banner-acesso-a-informacao-desktop/" }}) {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      backgroundBannerTiposDeFundosMobile: imageSharp(fluid: {originalName: { regex: "/background-banner-tipos-de-fundos-mobile/" }}) {
        fluid(maxWidth: 360, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      backgroundBannerTiposDeFundosDesktop: imageSharp(fluid: {originalName: { regex: "/background-banner-tipos-de-fundos-desktop/" }}) {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  useLayoutEffect(() => {
    if (windowWidth < WIDTH_MD) {
      setImageBanner(`url(${data[imageMobile].fluid.src}), url(${data[imageMobile].fluid.base64})`)
    } else {
      setImageBanner(`url(${data[imageDesktop].fluid.src}), url(${data[imageDesktop].fluid.base64})`)
    }
  }, [ windowWidth ])

  return (
    <Banner
      style={{ backgroundImage: imageBanner }}
      customStyle={customStyle}
      className='d-flex align-items-center'
    >
      {children}
    </Banner>
  )
}

export default BannerHeader
