
import styled from 'styled-components'
import breakpoints from '../../styles/breakpoints'
import { gray } from '../../styles/colors'

export const DocumentCardList = styled.div`

  .list-item {
    width: auto;
    border: 1px solid ${gray[550]};
    min-height: 110px;
    text-align: left;
    background-color: ${gray[400]};

    @media (min-width: ${breakpoints.lg}) {
      min-height: 138px;
    }

    .pdf-icon {
      height: 32px;
      width: 32px;
    }
  }
`
