import * as React from 'react'

import { Select, Option } from './styles'
interface IOption {
  description: string;
  id: string;
  name: string;
};

interface ISelectList {
  selectList: IOption[];
}

export default function CategoryMobileSelect ({ selectList }: ISelectList) {
  const changeCategoryPage = (categoryId: string) => {
    window.location.replace(`/acesso-a-informacao?category=${categoryId}`)
  }

  return (
    <Select defaultValue={selectList[0].id}>
      {selectList.map((option: IOption) => (
        <Option key={option.id} value={option.id} onClick={() => changeCategoryPage(option.id)}>{option.name}</Option>
      ))}
    </Select>
  )
}
